button .loader {
  margin-right: 5px;
}

button .icon {
  height: 100%;
  border-left: 1px solid white;
  display: flex;
  align-items: center;
  padding-left: 11px;
  margin-left: 15px;
}
