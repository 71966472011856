@import "../../index.scss";

.holder {
  width: 100%;
  display: flex;
  position: relative;
}

.holder input {
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer !important;
  opacity: 0;
  z-index: 2;
}

.holder label {
  display: block;
  font-size: 10px;
  line-height: 15px;
  color: $charbon-color;
  margin-top: 10px;
}

.holder label.hasError {
  color: $sexy-red;
}

@include mobile {
  .holder {
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
