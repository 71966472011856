@import "assets/fonts/nexa-bold/nexabold-font";
@import "assets/fonts/opensans-regular/opensans-font";

// Variables

$light-gray: #cdcdcd;
$gray: #f9f9f9;
$dark-gray: #f2f2f2;
$charbon-color: #7d7d7d;
$sexy-red: #fa372c;

$small-mobile-width-trigger: 520px;
$mobile-width-trigger: 768px;
$tablet-width-trigger: 980px;
$small-screen-width-trigger: 1180px;

$field-height: 45px;

$box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.3);

// Responsiveness

@mixin small-mobile {
  @media (max-width: #{$small-mobile-width-trigger}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile-width-trigger}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tablet-width-trigger}) {
    @content;
  }
}

@mixin small-screen {
  @media (max-width: #{$small-screen-width-trigger}) {
    @content;
  }
}

// Page

body {
  margin: 0;
  font-family: OpenSans, "sans-serif";
  color: black;
  font-size: 14px;
  line-height: 149.8%;
}

b {
  font-family: NexaBold, "sans-serif";
  font-weight: bold;
}

// Headers

h1 {
  font-family: NexaBold, "sans-serif";
  font-weight: bold;
  font-size: 30px;
  line-height: 33px;
  color: black;
  margin-bottom: 30px;
}

h3 {
  font-family: NexaBold, "sans-serif";
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: black;
  margin-bottom: 15px;
}

@include mobile {
  h1 {
    font-size: 24px;
    line-height: 26px;
  }
}

// Widgets

textarea,
input,
select {
  width: 100%;
  border: 1px solid $light-gray;
  border-radius: 5px;
  font-size: 14px;
  background-color: white;
  padding: 0 15px;
  box-sizing: border-box;
  outline: none;

  font-family: OpenSans, "sans-serif";
  color: black;
  font-size: 14px;
  line-height: 149.8%;

  transition: border 0.3s;
}

textarea:focus,
input:focus,
select:focus {
  border-color: black;
}

textarea {
  height: 135px !important;
  padding: 10px 15px;

  font-family: OpenSans, "sans-serif";
  color: black;
  font-size: 14px;
  line-height: 149.8%;

  resize: none;
}

input,
select {
  height: $field-height;
}

::placeholder {
  color: $charbon-color;
}

button {
  cursor: pointer;
  height: 35px;
  padding: 0 25px;
  background-color: $sexy-red;
  border-radius: 20px;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: NexaBold, "sans-serif";
  font-weight: 400;
  font-size: 12px;
  color: white;
  transition: all 0.3s;
}

button.reversed {
  border: 2px solid $sexy-red;
  background-color: white;
  color: $sexy-red;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

// Layout

.container {
  width: 100%;
  max-width: 1400px;
  padding: 30px;
  box-sizing: border-box;
  overflow-x: hidden;
}

.dualCols {
  display: flex;
}

.dualCols > div {
  flex: 0.5;
}

.dualCols > div:first-of-type {
  margin-right: 40px;
}

@include mobile {
  .container {
    padding: 20px;
  }

  .dualCols {
    flex-direction: column;
  }

  .dualCols > div {
    flex: 1;
  }

  .dualCols > div:first-of-type {
    margin-right: 0;
  }
}
