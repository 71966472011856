@import "../../index.scss";

.holder {
  position: relative;
  width: 100%;
}

.holder .popOver {
  position: absolute;
  bottom: calc(100%);
  background-color: white;
  z-index: 2;

  padding: 10px;
  border-radius: 5px;
  transition: all 0.3s;
  box-shadow: $box-shadow;
}

.holder .popOver:after {
  content: " ";
  border-top: 8px solid white;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  position: absolute;
  bottom: -6px;
  border-radius: 15px;
}

.holder .popOver.left {
  left: -10px;
}

.holder .popOver.left:after {
  left: 25px;
}

.holder .popOver.center:after {
  left: calc(50% - 8px);
}

.holder .popOver.right {
  right: -8px;
}

.holder .popOver.right:after {
  right: 10px;
}
