@font-face {
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 400;
  src: url(./opensans-regular.eot);
  src: local("OpenSans"),
    url(./opensans-regular.eot?#iefix) format("embedded-opentype"),
    url(./opensans-regular.woff2) format("woff2"),
    url(./opensans-regular.woff) format("woff"),
    url(./opensans-regular.ttf) format("truetype"),
    url(./opensans-regular.svg#open_sansregular) format("svg");
}
