@import "../../index.scss";

.picker button {
  text-transform: uppercase;
  height: auto;
  padding: 0;
  background: none;
  color: $light-gray;
  margin-left: 12px;
  transition: color 0.3s;
}

.picker button:hover,
.picker button.active {
  color: $charbon-color;
}

@include mobile {
  .picker {
    right: 20px;
    top: 23px;
  }
}
