@import "../../index.scss";

.holder {
  display: inline-block;
  vertical-align: top;
}

.holder button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 1px solid $charbon-color;
  width: 20px;
  height: 20px;
  background: none;
  border-radius: 50%;
  margin-right: 15px;
  vertical-align: top;
}

.holder button img {
  width: 70%;
  opacity: 0;
  transition: all 0.3s;
}

.holder.checked button {
  background: $charbon-color;
}

.holder.checked button img {
  opacity: 1;
}

.holder label {
  vertical-align: top;
}
