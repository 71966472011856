@import "../../index.scss";

$animation-duration: 0.3s;

.holder {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  box-sizing: border-box;
  pointer-events: none;
  transition: all $animation-duration;
  opacity: 0;
}

.modal {
  height: 100%;
  width: 100%;
  max-width: 1000px;
  background: white;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 50px;
  transform: translateY(20px);
  transition: all $animation-duration;
  overflow-y: scroll;
  overflow-x: hidden;
}

.holder.visible {
  opacity: 1;
  pointer-events: all;
}

.holder.visible .modal {
  transform: translateY(0px);
}

.modal .title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.modal .title h1 {
  margin: 0;
}

.modal .title button {
  padding: 5px;
  margin-left: 50px;
  background: none;
  transition: all 0.3s;
}

.modal .title button:hover {
  transform: scale(1.2);
}

@include mobile {
  .holder {
    padding: 20px;
  }

  .modal {
    padding: 25px;
  }

  .modal .title {
    flex-direction: column-reverse;
    align-items: flex-end;
  }

  .modal .title h1 {
    margin-top: 20px;
  }
}
