@import "../../index.scss";

.holder {
  margin-bottom: 25px;
}

.wrapper {
  position: relative;
}

.wrapper .iconHolder {
  position: absolute;
  left: 0;
  top: 1px;
  height: $field-height;
  width: $field-height;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid $light-gray;
  height: calc(100% - 2px);
}

.wrapper .iconHolder img {
  max-width: 50%;
}

.wrapper .popOverHolder {
  position: absolute;
  right: 12px;
  top: 12px;
}

.wrapper .popOverHolder img {
  width: 20px;
  pointer-events: initial;
}

.wrapper .popOverHolder p {
  font-size: 10px;
  line-height: 15px;
  color: $charbon-color;
  margin: 0;
}

.holder.hasError {
  margin-bottom: 5px;
}

.holder.hasError input {
  border-color: $sexy-red;
}

.holder label {
  color: $sexy-red;
  font-size: 12px;
}
