@import "../../index.scss";

footer {
  margin-top: 150px;
  display: flex;
  align-items: flex-start;
}

footer h3 {
  color: $sexy-red;
  margin-top: 10px;
}

footer .rightContent {
  border-left: 1px solid $light-gray;
  margin-left: 20px;
  padding-left: 20px;
  flex: 1;
}

footer .rightContent a {
  display: block;
  color: $charbon-color;
  text-decoration: none;
  margin-bottom: 15px;
}

footer .rightContent .legalContent {
  display: flex;
  justify-content: space-between;
  margin-top: 70px;
}

footer .rightContent .legalContent a {
  margin-bottom: 0;
  color: black;
  font-family: NexaBold, "sans-serif";
}

@include mobile {
  footer {
    flex-direction: column;
    width: 100%;
  }

  footer .rightContent {
    border-top: 1px solid $light-gray;
    border-left: none;
    margin-left: 0px;
    padding-left: 0px;
    margin-top: 20px;
    padding-top: 20px;
    width: 100%;
  }
}
