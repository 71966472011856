@import "../../index.scss";

$left-section-width: 400px;

.left {
  width: $left-section-width;
  height: calc(100vh - 60px);
  max-height: 100%;
  overflow: hidden;
  background-color: $gray;
  display: flex;
  align-items: flex-end;
  border-radius: 5px;
  position: fixed;
}

.left img {
  height: 195px;
}

.header {
  position: absolute;
  left: $left-section-width + 50px + 30px;
  right: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header > div {
  display: flex;
  align-items: center;
}

.header .bulkCreateButton {
  margin-right: 30px;
  border-radius: 5px;
  padding: 0 10px;
}

.right {
  padding-left: $left-section-width + 50px;
  margin-top: 50px;
}

.linkHolder {
  background-color: $gray;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  height: 60px;
  border-radius: 5px;
}

.linkHolder h3 {
  margin: 0;
}

.linkHolder .linkButton {
  display: flex;
  color: $sexy-red;
  background-color: transparent;
  font-family: NexaBold, "sans-serif";
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  padding: 0;
}

.linkHolder .linkButton img {
  height: 12px;
  margin-left: 15px;
  transition: margin 0.3s;
}

.linkHolder .linkButton:hover img {
  margin-right: 10px;
  margin-left: 10px;
}

.right label.hasError {
  display: block;
  color: $sexy-red;
  font-size: 12px;
  margin-top: 5px;
}

.pageTitle {
  max-width: 600px;
}

@include small-screen {
  $left-section-width: 300px;

  .left {
    width: $left-section-width;
  }

  .right {
    padding-left: $left-section-width + 50px;
  }

  .header {
    left: $left-section-width + 50px + 30px;
  }
}

@include tablet {
  .left {
    width: 100%;
    height: 180px;
    position: relative;
    justify-content: center;
    margin-bottom: 30px;
  }

  .left img {
    height: auto;
  }

  .header {
    position: relative;
    left: 0;
    right: 0;
  }

  .header .bulkCreateButton {
    margin-right: 5px;
  }

  .right,
  .header {
    padding-left: 0;
  }

  .right {
    margin-top: 0;
  }
}

@include mobile {
  .linkHolder {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    height: auto;
  }

  .linkHolder h3 {
    margin-bottom: 10px;
  }
}

@include small-mobile {
  .header {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 50px;
  }

  .header > div {
    margin-top: 10px;
  }
}
