@import "../../index.scss";

.preview {
  flex: 1;
  margin-right: 20px;
  background-position: center;
  cursor: pointer;
  transition: border 0.3s;
  background-repeat: no-repeat;
  background-size: cover;
  border: 2px dashed $charbon-color;
}

.dragging .preview {
  border: 2px dashed $sexy-red;
}

@include mobile {
  .preview {
    margin-bottom: 15px;
  }
}
