@import "../../../index.scss";

.holder p {
  color: $charbon-color;
  margin-top: 0;
  margin-bottom: 5px;
}

.holder .field {
  margin-bottom: 5px;
}
