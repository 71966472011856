@font-face {
  font-family: "NexaBold";
  font-style: normal;
  font-weight: 500;
  src: url(./nexabold-bold.eot);
  src: local("NexaBold"), local("Nexa Bold"),
    url(./nexabold-bold.eot?#iefix) format("embedded-opentype"),
    url(./nexabold-bold.woff2) format("woff2"),
    url(./nexabold-bold.woff) format("woff"),
    url(./nexabold-bold.ttf) format("truetype"),
    url(./nexabold-bold.svg#nexa_boldregular) format("svg");
}
