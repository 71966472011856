@import "../../index.scss";

.holder p {
  color: $charbon-color;
  margin-top: 0;
  font-size: 12px;
}

.holder .cropperHolder {
  position: relative;
  height: 300px;
  margin-bottom: 5px;
}

.holder .slider {
  height: 30px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.holder .slider .track {
  height: 3px;
  border-radius: 1.5px !important;
  background-color: $sexy-red;
  opacity: 0.3;
  cursor: pointer;
}

.holder .slider .track:first-of-type {
  opacity: 1;
}

.holder .slider .thumb {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: $sexy-red;
  cursor: move;
  transition: box-shadow 0.3s;
}

.holder .slider .thumb:hover,
.holder .slider .thumb.active {
  box-shadow: $box-shadow;
}
