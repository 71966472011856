@import "../../../index.scss";

.holder h3 {
  margin-top: 50px;
}

.holder img {
  max-width: 100%;
}

.submitDisclaimer {
  background: $gray;
  padding: 25px;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 30px 0;
}

.submitDisclaimer p {
  margin: 0;
  margin-bottom: 20px;
}

.helpDisclaimer {
  margin-top: 40px;
  margin-bottom: 0;
}

.helpDisclaimer a {
  color: black;
}
